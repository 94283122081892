<template>
    <div class="row" style="margin-top:1%;">
        <div class="column" style="width: 100%;">
            <v-textarea
            outlined
            class="row" style="width: 70%;"
            >
            </v-textarea>
        </div>
        <div v-if="tutorIsNotHere">
            <v-alert class="column" style="width: 50%;"
            type="error"
            left bottom absolute>
            Tutor is not here
            </v-alert>
        </div>
        <div class="column" style="width:100%;margin-top:1%;">
            <v-navigation-drawer
            absolute
            permanent
            right
            style="margin-top:5%;height:50%;"
            >
            <template v-slot:prepend>
                <v-list-item two-line>
                <v-list-item-avatar>
                    <img src="https://randomuser.me/api/portraits/women/81.jpg">
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Hari@om.com</v-list-item-title>
                    <v-list-item-subtitle>Logged In</v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
            </template>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item
                v-for="item in items"
                :key="item.title"
                >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-navigation-drawer>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            tutorIsNotHere:true,
            drawer: true,
            items: [
            { title: 'Home', icon: 'mdi-home-city' },
            { title: 'My Account', icon: 'mdi-account' },
            { title: 'Users', icon: 'mdi-account-group-outline' },
            ],
            mini: true,
            slotsList:[],
            proposer_name:""
        }
    },
    methods: {
        async getSlotsFn(){
        console.log("resp",this.work_api_Base_URL);
        try{
            let resp = await axios.get(this.work_api_Base_URL + "/v/me/get_upcoming_slots")
            console.log("response from get_upcoming_slots", resp)
            this.slotsList = resp.data
        }catch(error){
            console.log("error in resp of get_upcoming_slots is:", error.response)
            this.showRespMsg = true
            this.respMsg = error.response.headers["message"]
        }
        },
    },
}
</script>
<style>
.column {
  float: left;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>